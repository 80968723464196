<template>
    <v-dialog v-model="dialog" persistent max-width="800">
      <template v-slot:activator="{ on }">
        <v-btn :text="text" :color="color" :outlined="outlined" v-on="on" small :class="background ? myClass + ' btn-hover' : myClass"  height="36px" :style="minWidth ? 'min-width:' + minWidth + '!important;' : ''">
          <v-icon v-if="showIcon">mdi-domain-plus</v-icon>
          <span v-if="showButtonName" class="pl-2">Додати адресу</span>
        </v-btn>
      </template>

      <v-card flat min-height="560px" class="card-without-radius">
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="success darken-2"
            grow>
            <!--ПОШУК ПО ОСОБОВОМУ РАХУНКУ-->
            <v-tab>Пошук по особовому рахунку</v-tab>
            <!--ПОШУК ПО АДРЕСІ-->
            <v-tab>Пошук по адресі</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!--ПОШУК ПО ОСОБОВОМУ РАХУНКУ-->
            <v-tab-item>
              <SearchByPersonIdInternal :redirect="false" :prependIcon="!$vuetify.breakpoint.xsOnly" @getSecretIdByPersonIdInternal="getSecretIdFromSearch"/>
            </v-tab-item>
            <!--ПОШУК ПО АДРЕСІ-->
            <v-tab-item>
              <SearchByAddress :redirect="false" :prependIcon="!$vuetify.breakpoint.xsOnly" @getSecretIdByAddress="getSecretIdFromSearch"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>

      <v-card flat class="card-without-radius">
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="info" @click="dialog = false">Закрити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
    import SearchByAddress from '@/components/searchAccount/SearchByAddress'
    import SearchByPersonIdInternal from '@/components/searchAccount/SearchByPersonIdInternal'
    import {
      SEARCH_ACCOUNT_CLEAR,
    } from '@/store/modules/searchAccount/mutation-types';

    export default {
    name: "SearchDialog",
    props: {
      color: {
        type: String,
        default() {
            return '';
        }
      },
      text: {
        type: Boolean,
        default() {
          return true;
        }
      },
      background: {
          type: Boolean,
          default() {
              return false;
          }
      },
      outlined: {
          type: Boolean,
          default() {
              return true;
          }
      },
      showIcon: {
          type: Boolean,
          default() {
              return true;
          }
      },
      showButtonName: {
          type: Boolean,
          default() {
              return true;
          }
      },
      minWidth: {
          type: String,
          default() {
              return '';
          }
      },
        myClass: {
          type: String,
            default() {
              return 'font-weight-regular body-2 white--text'
            }
        }
    },
    components: {
        SearchByPersonIdInternal,
        SearchByAddress
    },
    data () {
        return {
            tab: null,
            dialog: false
        }
    },
    methods: {
        getSecretIdFromSearch(value) {
            this.dialog = false;
            this.$emit('getSecretIdFromSearch', value);
            this.$store.commit(SEARCH_ACCOUNT_CLEAR);
        }
    },
  }
</script>

<style scoped>
  .card-without-radius {
    border-radius: 0 !important;
  }
  .btn-hover {
    background: rgba(25, 118, 210, 0.1);
  }
</style>
