<template>
  <Component :is="getComponent(field.type)" :name="field.name || ''" :schema="field.schema" :styleSchema="field.styleSchema" v-model="field.value"/>
</template>

<script>
    import DynamicTextField from '@/components/generator/DynamicTextField.vue'
    import DynamicDateField from '@/components/generator/DynamicDateField.vue'
    import DynamicSelectionControlField from '@/components/generator/DynamicSelectionControlField.vue'
    import DynamicSelectField from '@/components/generator/DynamicSelectField.vue'
    import DynamicAlert from '@/components/generator/DynamicAlert.vue'

    const INPUT = "input";
    const CHECKBOX = "checkbox";
    const SELECT = "select";
    const DATE = "date";
    const MESSAGE = "message";


    const COMPONENTS = {
        [INPUT]: DynamicTextField,
        [CHECKBOX]: DynamicSelectionControlField,
        [SELECT]: DynamicSelectField,
        [DATE]: DynamicDateField,
        [MESSAGE]: DynamicAlert,
    };

    export default {
        name: "DynamicField",
        props: ['field'],
        components: {
            DynamicTextField,
            DynamicDateField,
            DynamicSelectionControlField,
            DynamicSelectField,
            DynamicAlert
        },
        computed: {
        },
        methods: {
          getComponent(type) {
              return COMPONENTS[type] || undefined
              // if (type === 'input') {
              //     return DynamicTextField
              // } else if (type === 'checkbox') {
              //     return DynamicSelectionControlField
              // } else if (type === 'select') {
              //     return DynamicSelectField
              // } else if (type === 'date') {
              //     return DynamicDateField
              // } else {
              //     return undefined
              // }
          }
        }
    }
</script>

<style scoped>

</style>
