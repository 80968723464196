<template>
  <div>
    <template v-if="schema.fieldType === 'checkbox'">
      <v-checkbox
        v-model="innerValue"
        :label="schema.label"
        :rules="getValidators()"
        class="my-0"
        v-bind="$attrs.styleSchema || {}"
      >
      </v-checkbox>
    </template>
    <template v-else-if="schema.fieldType === 'radio'">
      <v-radio-group
        v-model="innerValue"
        :rules="getValidators()"
        class="my-0"
        v-bind="$attrs.styleSchema || {}"
      >
        <v-radio
          v-for="(item, index) in schema.values"
          :key="index"
          :label="item.text || ''"
          :value="item.value || false"
          v-bind="item.styleSchema || {}"
        />
      </v-radio-group>
    </template>
    <template v-else-if="schema.fieldType === 'switch'">
      <v-switch
        v-model="innerValue"
        :label="schema.label"
        :rules="getValidators()"
        class="my-0"
        v-bind="$attrs.styleSchema || {}"
      >
      </v-switch>
    </template>
  </div>
</template>

<script>
    import { VALIDATION_RULES } from "@/utils";

    export default {
        name: "DynamicSelectionControlField",
        inheritAttrs: false,
        props: {
            value: {
                default() {
                    return undefined
                }
            },
            name: {
              type: String,
              default() {
                return 'text';
              }
            },
            fieldType: {
                type: String,
                default() {
                    return '';
                }
            },
            schema: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        computed: {
            innerValue: {
                get: function () {
                    return this.value
                },
                set: function (value) {
                   this.$emit('input', value)
                }
            },
            rules() {
                return VALIDATION_RULES;
            },
        },
        methods: {
            getValidators() {
                if (this.schema.validators) {
                    let validators = [];
                    this.schema.validators.forEach(elem => {
                        validators.push(VALIDATION_RULES[elem])
                    });
                    return validators
                }
            }
        }
    }
</script>

<style scoped>

</style>
