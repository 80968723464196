export default {
  services() {
    return [
      {text: 'service1', value: 1},
      {text: 'service2', value: 2},
      {text: 'service3', value: 3},
      {text: 'service4', value: 4},
      {text: 'service5', value: 5},
      {text: 'service6', value: 6},
      {text: 'service7', value: 7},
    ]
  }
};
