<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="innerValue"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="schema.label"
        :placeholder="schema.placeholder"
        :value="computedDateFormatted"
        :hint="schema.hint"
        :rules="getValidators()"
        readonly
        v-on="on"
        v-bind="$attrs.styleSchema || {}"
      ></v-text-field>
    </template>
    <v-date-picker v-model="innerValue" scrollable v-bind="$attrs.datePickerSchema || {}">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(innerValue)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
    import { VALIDATION_RULES } from "@/utils";
    import GeneratorCallback from "@/components/generator/generatorCallback"

    export default {
        name: "DynamicTextField",
        inheritAttrs: false,
        props: {
            value: {
                default() {
                    return undefined
                }
            },
            schema: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data: () => {
            return {
                modal: false,
            }
        },
        computed: {
            innerValue: {
                get: function () {
                    return this.value
                },
                set: function (value) {
                   this.$emit('input', value)
                }
            },
            rules() {
                return VALIDATION_RULES;
            },
            computedDateFormatted () {
                return this.innerValue ? this.$moment(this.innerValue).format('dddd, MMMM Do YYYY') : ''
            }
        },
        methods: {
            getValidators() {
                if (this.schema.validators) {
                    let validators = [];
                    this.schema.validators.forEach(elem => {
                        validators.push(VALIDATION_RULES[elem])
                    });
                    return validators
                }
            },
            getItems(callback) {
                return GeneratorCallback[callback].call() || []
            },
        },
    }
</script>

<style scoped>

</style>
