import http from '@/lib/http'

const DASHBOARD_URL = '/account/dashboard';
const ACCOUNT_VIEW = '/account/view';
const ACCOUNT_UPDATE = '/account/service/update';
const ADDRESS_UPDATE = '/account/address/update';

export default {
  dashboard(person_id) {
    return http.post(
      DASHBOARD_URL,
      {
        person_id
      }
    )
  },
  accountView(payload) {
    return http.post(
      ACCOUNT_VIEW,
      {
        ...payload
      }
    )
  },
  accountServiceUpdate(payload) {
    return http.post(
      ACCOUNT_UPDATE,
      {
        ...payload
      }
    )
  },
  addressUpdate(secret_id, full_name) {
    return http.post(
      ADDRESS_UPDATE,
      {
        secret_id,
        full_name
      }
    )
  },
};
