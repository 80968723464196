<template>
  <v-select
    v-model="innerValue"
    :label="schema.label"
    :placeholder="schema.placeholder"
    :items="getItems(schema.callback) || []"
    :hint="schema.hint"
    :rules="getValidators()"
    class="my-0"
    v-bind="$attrs.styleSchema || {}"
  >
  </v-select>
</template>

<script>
    import { VALIDATION_RULES } from "@/utils";
    import GeneratorCallback from "@/components/generator/generatorCallback"

    export default {
        name: "DynamicTextField",
        inheritAttrs: false,
        props: {
            value: {
                default() {
                    return undefined
                }
            },
            schema: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        computed: {
            innerValue: {
                get: function () {
                    return this.value
                },
                set: function (value) {
                   this.$emit('input', value)
                }
            },
            rules() {
                return VALIDATION_RULES;
            },
        },
        methods: {
            getValidators() {
                if (this.schema.validators) {
                    let validators = [];
                    this.schema.validators.forEach(elem => {
                        validators.push(VALIDATION_RULES[elem])
                    });
                    return validators
                }
            },
            getItems(callback) {
                return GeneratorCallback[callback].call() || []
            }
        }
    }
</script>

<style scoped>

</style>
