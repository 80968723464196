<template>
  <v-alert
    text
    color="blue"
    class="my-1 mb-4"
    v-bind="$attrs.styleSchema || {}"
  >
    {{ value }}
  </v-alert>
</template>

<script>
    export default {
        name: "DynamicTextField",
        inheritAttrs: false,
        props: {
            value: {
                default() {
                    return undefined
                }
            },
        },
    }
</script>

<style scoped>

</style>
