<template>
  <div class="subcategories">
    <div class="grey lighten-4">
      <v-container fluid class="container-max-width py-2 grey--text text--darken-2">
        <div class="grey lighten-4 py-2">
          <v-row no-gutters class="flex-nowrap" align="start">
                <span>
                  <v-icon
                    size="56px"
                    color="primary"
                    class="pr-4">
                    mdi-playlist-check
                  </v-icon>
                </span>
            <div>
              <h3 class="text-uppercase">Перелік організацій та послуг</h3>
              <p class="mb-1">Усі доступні послуги по обраній категорії</p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>

    <v-container fluid class="container-max-width py-0 grey--text text--darken-2 mt-1 mb-2">
      <v-row class="white pa-3">
        <v-col cols="12" md="12" sm="12" xs="12" class="mb-2 v-col-padding pt-2 pb-0">
          <locality-autocomplete :schema="$route.params['schema']" :searchForm="true" :showPrependIcon="false" :disabled="false" @change="locality = $event"/>
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12" class="mt-1 mb-1 v-col-padding py-0">
          <v-text-field
            v-model="searchOrganisation"
            name="organisation"
            type="text"
            label="Назва, ЄДРПОУ, рахунок"
            hint="Пошук"
            required
            outlined
            hide-details
            @change.native.stop="filterQuery"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12" class="mt-1 mb-0 v-col-padding py-1">
          <v-btn v-if="modal === false" tile color="primary lighten-1" router :to="{ name: 'templates' }">Повернутися до вибору груп</v-btn>
          <v-btn v-else tile color="primary lighten-1" @click="goToDashboard">Повернутися до вибору груп</v-btn>
        </v-col>
        <template v-if="loader">
          <v-col
            md="6"
            sm="12"
            v-for="item in limit"
            :key="item"
            class="d-flex child-flex px-4"
          >
            <v-card style="cursor: pointer; position: relative; overflow: hidden;" width="300px">
              <v-skeleton-loader
                type="list-item-avatar-three-line">
              </v-skeleton-loader>
            </v-card>
          </v-col>
        </template>
        <template v-else>
          <v-col
            md="6"
            sm="12"
            v-for="(item, index) in fetched" :key="index"
            class="d-flex child-flex px-4">
            <sub-category-item :item="item" :index="index"/>
          </v-col>
        </template>
        <v-col md="12" sm="12" v-if="showSearchError || !showPagination && !firstLoad && !loader">
          <v-card height="200px" class="d-flex align-center">
            <v-card-text class="text-center title font-weight-medium"
                         v-text="showSearchError ? 'Пошук за вказаними критеріями не дав результату. Спробуйте виконати пошук за іншими критеріями...' : 'Нажаль жодної псослуги не знайдено...' ">
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12" sm="12" class="text-center" v-if="showPagination">
          <div class="text-center d-inline-flex align-center" >
            <v-btn text small color="success" :disabled="offset===0" @click="pagination('left')">
              <v-icon left>mdi-arrow-left-bold</v-icon>
              Попередня
            </v-btn>
            <v-btn fab text small color="success" class="mx-2" @click="pagination('start')" :disabled="offset===0">
              <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn text small color="success" @click="pagination('right')" :disabled="!nextPage">
              Наступна
              <v-icon right>mdi-arrow-right-bold</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import SubCategoryItem from "./SubCategoryItem";
  import LocalityAutocomplete from '@/components/locality/LocalityAutocomplete.vue'
  import {ServicesAPI} from '@/api/Services';

  export default {
    name: "SubCategoriesList",
    props: ['schema', 'group', 'modal', 'openDialog'],
    components: {SubCategoryItem, LocalityAutocomplete},
    data() {
      return {
        searchOrganisation: '',
        services: [],
        page: 1,
        offset: 0,
        limit: 8,
        firstLoad: false,
        loader: false,
        useLoader: true,
        locality: undefined
      }
    },
    computed: {
      fetched() {
        return this.services || [];
      },
      nextPage() {
        if (this.fetched.length === 0 && this.offset === 0 && !this.firstLoad) {
          return true;
        } else {
          return this.fetched.length >= this.limit;
        }
      },
      showSearchError() {
        return (this.searchOrganisation.length > 0 || this.locality) && this.fetched.length === 0
      },
      showPagination() {
        return this.services.length > 0 || this.offset > 0;
      }
    },
    methods: {
      async fetchData(load = false) {
        this.loader = this.useLoader;
        let payload = {
          'schema': this.$route.params['schema'] || this.schema,
          'catalogue': this.$route.params['group'] || this.group,
          'offset': this.offset,
          'limit': this.limit,
        };

        if (this.searchOrganisation.length >= 3) {
          payload['filter'] = {'query': this.searchOrganisation}
        }
        if (this.locality)  {
          if (payload.filter) {
            payload.filter['locality'] = this.locality.id;
          } else {
            payload['filter'] = {'locality':  this.locality.id};
          }
        }

        try {
          const {data} = await ServicesAPI.view(payload);
          this.services = data;
          this.loader = false;
          this.firstLoad = load
        } catch (e) {
          this.loader = false;
          this.firstLoad = load
        }
      },
      filterQuery() {

        if (this.searchOrganisation.length >= 3 || this.locality)  {
          this.fetchData();
          return;
        }
        if (this.searchOrganisation.length === 0 || !this.locality) {
          this.fetchData();
        }
      },
      pagination(direction) {
        if (direction === 'left') {
          this.offset = this.offset === 0 ? 0 : this.offset - this.limit;
        } else if (direction === 'right') {
          this.offset += this.limit;
        } else {
          this.offset = 0
        }
        this.useLoader = false;
        this.filterQuery();

      },
      goToDashboard() {
        this.$eventBus.$emit("modalRoute", { component: 'categories-list', openDialog: this.openDialog === true ? false : undefined })
      }
    },
    watch: {
      locality() {
        this.filterQuery();
      }
    },
    mounted() {
      this.fetchData(true);
    }
  }
</script>

<style scoped>
  .container-max-width {
    max-width: 1200px;
  }
</style>
