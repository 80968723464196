<template>
  <v-card>
    <v-card-text class="py-3 primary lighten-2" dark>
      <h3 v-if="formSchema.title" class="title text-center white--text">
        {{ formSchema.title }}
      </h3>
    </v-card-text>
    <v-card-text class="mt-2 pb-0">
      <v-container fluid class="ma-0 grid-list-xs pb-0">
        <v-form :ref="formName" v-model="formValid" v-bind="$attrs.schema || {}">
<!--          <v-row class="my-1 py-0 success" >-->
<!--            <v-col cols="12">-->
<!--              <h3 v-if="formSchema.title" dark class="title text-center">-->
<!--                {{ formSchema.title }}-->
<!--              </h3>-->
<!--            </v-col>-->
<!--          </v-row>-->
          <v-row v-for="(row, index) in rows" :key="`row-${index}`">
            <v-col v-for="(field, index) in row" :key="`field-${index}`" :cols="cols(field.cols || 12, field.colsMd, field.colsSm, field.colsXs)" class="my-0 py-0" dense>
              <DynamicField @getAppointment="getAppointment" :field="formFields[field.id]"/>
            </v-col>
          </v-row>
          <v-row class="my-1 py-0">
            <v-col cols="12">
              <v-alert text color="blue lighten-1" class="my-0" v-if="formSchema.comment">
                {{ formSchema.comment }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="blue darken-1" text @click="close">{{ formSchema.BtnClose || "закрити"  }}</v-btn>
      <v-btn color="blue darken-1" text @click="submit">{{ formSchema.BtnSubmit || "зберегти"  }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import DynamicField from '@/components/generator/DynamicField.vue'
    import { cloneDeep, get } from 'lodash'

    export default {
        name: "DynamicForm",
        inheritAttrs: false,
        props: ["config", 'formData', 'submitCallback', 'formName', 'index'],
        components: {
            DynamicField
        },
        computed: {
          // rows_prev() {
          //       let lastRow = 0;
          //       let a = {};
          //       return this.fields.reduce(function(h, obj, idx) {
          //           const hasRow = !!obj.row;
          //           if (hasRow) {
          //               if (lastRow > +obj.row && !a[obj.row]) {
          //                   lastRow++;
          //                   a[obj.row] = lastRow;
          //               }
          //               lastRow = a[obj.row] ? a[obj.row] : +obj.row
          //           } else {
          //               lastRow++
          //           }
          //           return Object.assign(h, { [lastRow]: (h[lastRow] || []).concat( {obj: obj, id: idx}) })
          //       }, {});
          //   },
          rows() {
            let lastRow = 0;
            let a = {};
            return this.formFields.reduce(function(h, obj, idx) {
              const hasRow = !!obj.row;
              if (hasRow) {
                if (lastRow > +obj.row && !a[obj.row]) {
                  lastRow++;
                  a[obj.row] = lastRow;
                }
                lastRow = a[obj.row] ? a[obj.row] : +obj.row
              } else {
                lastRow++
              }
              return Object.assign(h, { [lastRow]: (h[lastRow] || []).concat( {id: idx, cols: obj.cols, colsMd: obj.colsMd, colsSm: obj.colsSm, colsXs: obj.colsXs}) })
            }, {});
          }
        },
        data() {
            return {
              formValid: false,
              formFields: cloneDeep(this.config.fields) || [],
              formSchema: cloneDeep(this.config.schema) || {},
              outerData: cloneDeep(this.formData) || {},
            }
        },
        methods: {
          cols(col_lg=0, col_md=0, col_sm=0, col_xs=0) {
            const breakpoint = this.$vuetify.breakpoint.name;

            const breakpointList = {
              'xl': col_lg || '',
              'lg': col_lg || col_md || '',
              'md': col_md || col_lg || '',
              'sm': col_sm || col_md || col_lg || '',
              'xs': col_xs || col_sm || col_md || col_lg || '',
            };

            return breakpointList[breakpoint] || 'auto'
          },
          clearForm() {
            // this.formFields.forEach(item => {
            //   item.value = '';
            // });
            this.$refs[this.formName].reset();
          },
          submit() {
                if (!this.$refs[this.formName]) {
                    // console.log('set a form name (formName)');
                    return
                }

                let excludeList = ['message', 'alert'];

                if (this.$refs[this.formName].validate() && this.formValid) {
                    if (this.submitCallback) {
                        const formValues = {};
                        this.formFields.filter(item => !(item.type in excludeList))
                                      .forEach(item => {
                                        formValues[item.name] = item.value;
                                      });

                       this.submitCallback(formValues);
                       this.clearForm();
                    }
                } else {
                  this.$snackbar("Не валідна", 'error')
                }
            },
          close() {
            this.$emit('rejectForm');
            this.clearForm();
          },
          templateObject(object, data) {
            for (let key in this[object]) {
              let itemValue =  this[object][key];

              if (!itemValue) continue;

              let re = new RegExp('[\\{]{0,2}[ ]*formData(?:\\.\\w+)+[ ]*[\\}]{0,2}', 'g');
              let rRe = itemValue.match(re);

              if (rRe === null) continue;

              let formData = this[data];

              rRe.forEach(item =>  {
                let forReplace = '' + item;
                let replacePath = forReplace.replace('{{', '').replace('}}', '').replace('formData.', '').trim();

                itemValue = itemValue.replace(forReplace, get(formData, replacePath, ''))
              });
              this[object][key] = itemValue;
            }
          },
          templateArray(object, data) {
            this[object].forEach(item => {
              let itemValue = item.value;
              let cont = false;

              if (!itemValue) cont = true;

              if (!cont) {
                let re = new RegExp('[\\{]{0,2}[ ]*formData(?:\\.\\w+)+[ ]*[\\}]{0,2}', 'g');
                let rRe = itemValue.match(re);

                if (rRe === null) cont = true;

                if (!cont) {
                  let formData = this[data];

                  rRe.forEach(rItem => {
                    let forReplace = '' + rItem;
                    let replacePath = forReplace.replace('{{', '').replace('}}', '').replace('formData.', '').trim();

                    itemValue = itemValue.replace(forReplace, get(formData, replacePath, ''))
                  });

                  item.value = itemValue;
                }
              }
            })
          },
          generateAppointment() {
              let itemValue = '' + this.formSchema.appointmentTemplate;
              let appointmentObj = this.formFields.find(element => (element.name || '').toLowerCase() === 'payment_destination') || {};

              if (!itemValue || !appointmentObj) return;

              let re = new RegExp('[\\{]{0,2}[ ]*(?:formData|field)(?:\\.\\w+)+[ ]*[\\}]{0,2}', 'g');
              let rRe = itemValue.match(re);

              if (rRe === null) return;



              rRe.forEach(rItem => {
                let forReplace = '' + rItem;
                let replacePath = forReplace.replace('{{', '')
                                            .replace('}}', '');

                if (replacePath.indexOf('field.') !== -1) {
                  replacePath = replacePath.replace('field.', '').trim();
                  let elem = this.formFields.find(element => (element.name || '').toLowerCase()  === replacePath.toLowerCase());
                  itemValue = itemValue.replace(forReplace, elem.value || '')
                } else {
                  itemValue = itemValue.replace(forReplace, get(this.outerData, replacePath, ''))
                }

              });

            appointmentObj.value = itemValue;

          },
          beforeData() {
            this.templateArray('formFields', 'outerData');
            this.templateObject('formSchema', 'outerData');
            this.getAppointment();
          },
          getAppointment() {
            this.generateAppointment();
          }
        },
      mounted() {
          this.beforeData();
      },
    }
</script>

<style scoped>

</style>
