<template>
    <v-card width="300px"
            class="cat-item py-1"
            style="cursor: pointer; position: relative; overflow: hidden;"
            @click.stop.prevent.capture="openDialog"
    >

      <v-list-item style="height: 60px;" class="py-1">
        <v-list-item-avatar
          size="40px"
          class="mr-6"
          :color="randomColor"
        >
<!--          <v-icon size="22px" color="white" v-html="item.icon || 'mdi-domain'"/>-->
          <i :class="`${getIcon(item.config)} custom-icon`"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 mb-1 text-wrap text-truncate" style="line-height: 1.3rem">{{ item.organization.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey darken-1" size="30px">mdi-menu-right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-dialog v-model="dialog" persistent max-width="600px" eager internal-activator overlay-opacity=".8" v-if="config">
        <template v-if="dialog">
          <DynamicForm :config="config" :formData="item" :submitCallback="submitCallback" @rejectForm="rejectForm" :formName="'form' + index"/>
        </template>

      </v-dialog>
    </v-card>

</template>

<script>
  import { shuffle } from 'lodash'
  import DynamicForm from '@/components/generator/DynamicForm.vue'
  import {getIcon} from "@/utils";

    export default {
      name: "CategoriesItem",
      props: ['item', 'index'],
      data() {
        return {
          dialog: false,
        }
      },
      components: { DynamicForm },
      computed: {
        randomColor() {
          const colors = [
            'success lighten-1',
            'success',
            'primary',
            'red',
            'primary lighten-1',
            'orange',
            'red lighten-1',
            'orange darken-1',
            'purple',
            'red lighten-1',
            'success lighten-1',
          ];

          let shuffled = shuffle(colors);

          let randomIndex = Math.floor(Math.random() * (colors.length));

          return shuffled[randomIndex];
        },
        config() {
          const elem = this.item;
          if (!elem['config']) return {form: {}};
          if (!elem.config['template']) return {form: {}};
          return elem.config.template.form;
        }
      },
      methods: {
        openDialog() {
          const elem = this.item;
          if (!elem['config']) {
            this.$snackbar("Нажаль налаштування для даної послуги не визначені", 'error');
            return;
          }

          if (!elem.config['template']) {
            this.$snackbar("Нажаль налаштування для даної послуги не визначені", 'error');
            return;
          }

          if (!elem.config.template['form']) {
            this.$snackbar("Нажаль налаштування для даної послуги не визначені", 'error');
            return;
          }

          this.dialog = true
        },
        submitCallback(form) {
          this.dialog = false;
          this.$snackbar(form, 'success');
        },
        rejectForm() {
          this.dialog = false;
        },
        getIcon
      }
    }
</script>

<style scoped lang="scss">
  .right-bg {
    width: 30%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .cat-item:hover {
    box-shadow: -1px 2px 7px 4px #808080a8 !important;
    transition: .5s;
  }


  .custom-icon {
    margin: 0 auto;
    &:before {
      color: white !important;
    }
  }
</style>
