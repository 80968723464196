import http from '@/lib/http'

const ADDRESS_VIEW = '/account/address/view';
const ADDRESS_ADD = '/account/address/add';
const ADDRESS_REMOVE = '/account/address/remove';

export default {
  addressView(schema, secret_id) {
    return http.post(
      ADDRESS_VIEW,
      {
        schema,
        secret_id
      }
    )
  },
  addressAdd(addresses) {
    return http.post(
      ADDRESS_ADD,
      {
        addresses
      }
    )
  },
  addressRemove(secret_id) {
    return http.post(
      ADDRESS_REMOVE,
      {
        secret_id
      }
    )
  }
};
