<template>
    <v-card class="ma-2 card-template" :width="cardWidth()">
      <v-card-text class="pb-2 pt-2 d-flex" style="font-size: 0.96em; height: 100px;" :style="large ? 'font-size: 0.96em;' : 'font-size: 0.89em;'">
        <div style="width: 87%">
          <h3 class="text-uppercase pt-2" style="line-height: 1.1em">{{ nameCopy }}</h3>
          <p class="mb-0 pt-1 text-truncate" style="line-height: 1.2em">{{ organizationCopy }}</p>
          <p class="mb-0 text-truncate" style="line-height: 1.2em">{{ serviceNameCopy }}</p>
        </div>
        <div class="text-right" style="width: 60px;">
          <v-avatar class="mt-2" :color="color" size="36px">
            <v-icon color="white">{{ iconCopy }}</v-icon>
          </v-avatar>
        </div>
      </v-card-text>
      <v-card-text class="d-flex py-2 font-weight-bold">
        <div style="width: 40%;" class="text-left">
          {{ payDateCopy }}
        </div>
        <div style="width: 60%;" class="text-right">
          {{ sumCopy }}
        </div>
      </v-card-text>
      <v-divider class="pb-2"></v-divider>
      <v-card-actions class="px-3 pt-0">
        <v-btn small text color="error">Вилучити</v-btn>
        <v-spacer></v-spacer>
        <v-btn small depressed :color="color">Редагувати</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
    export default {
      name: "TemplateItem",
      props: {
        icon: { type: String, default: 'mdi-finance' },
        color: { type: String, default: 'success' },
        name: { type: String, default: '' },
        organization: { type: String, default: '' },
        serviceName: { type: String, default: '' },
        payDate: { type: String, default: '' },
        sum: { type: String, default: '' },
        width: { type: Number, default: 360 },
        large: { type: Boolean, default: true }
      },
      data() {
        return {
          iconCopy: this.icon,
          nameCopy: this.name,
          organizationCopy: this.organization,
          serviceNameCopy: this.serviceName,
          payDateCopy: this.payDate,
          sumCopy: this.sum
        }
      },
      methods: {
        cardWidth() {
          const breakpoint = this.$vuetify.breakpoint.name;
          switch (true) {
            case breakpoint === 'xs': {
              return '100%'
            }
            case breakpoint === 'sm': {
              return '47%'
            }
            case breakpoint === 'md': {
              return (this.width - 10) + 'px'
            }
            case breakpoint === 'lg': {
              return (this.width - 10) + 'px'
            }
            case breakpoint === 'xl': {
              return (this.width + 20) + 'px'
            }
          }
        }
      }
    }
</script>

<style scoped lang="scss">
  @media screen and (max-width: 800px) and (min-width: 600px) {
    .card-template {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 1375px) and (min-width: 945px) {
    .card-template {
      width: 47% !important;
    }
  }
</style>
