<template>
  <div class="address">
    <v-dialog v-model="dialogFullScreen" fullscreen hide-overlay transition="dialog-bottom-transition" style="background: white;">
      <v-card class="grey lighten-4">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogFullScreen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Створення нового платежу або шаблону</v-toolbar-title>
        </v-toolbar>
        <v-container class="container-max-width pb-0 fill-height">
          <v-card class="pa-1" flat tile color="transparent" style="width: 100%">
<!--            <categories-list :innerPath="innerPath"></categories-list>-->
            <Component :is="getComponent()" :innerPath="innerPath" :modal="true" :schema="schema" :group="group" :openDialog="openDialog"></Component>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
    <v-container class="container-max-width pb-0">
      <v-expansion-panels class="my-0" v-model="panel">
        <v-expansion-panel v-if="!old_version" class="grey lighten-5" @change="onTemplateExpChange">
          <v-expansion-panel-header class="pl-3 subtitle-2">
            <div class="py-1">
              <v-row no-gutters class="flex-nowrap" align="start">
                <span>
                  <v-icon
                    size="56px"
                    color="primary"
                    class="pr-4">
                    mdi-credit-card-plus-outline
                  </v-icon>
                </span>
                <div style="position: relative; top: 5px; font-size: 0.99em !important;">
                  <h3 class="text-uppercase grey--text text--darken-1">Створення нового платежу або шаблону</h3>
                  <p class="mb-1 grey--text text--darken-1 exp-subtitle" :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : ''" style="font-size: 1.1em !important;">
                   Ви зможете здійснити будь-який платіж за реквізитами, або обрати із списку уже готовий шаблон платежу</p>
                </div>
              </v-row>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card class="pa-1" flat tile color="transparent">
              <categories-list :innerPath="innerPath" :modal="true" :openDialog="true" :height="54" :showTitle="false" :background="''"></categories-list>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="px-0 grey lighten-5">
          <v-expansion-panel-header class="pl-3 subtitle-2">
            <div class="py-1">
              <v-row no-gutters class="flex-nowrap" align="start">
                <span>
                  <v-icon
                    size="52px"
                    color="success"
                    class="pr-5">
                    mdi-home-city-outline
                  </v-icon>
                </span>
                <div style="position: relative; top: 2px; font-size: 0.99em !important;">
                  <h3 class="text-uppercase grey--text text--darken-2"><span class="font-weight-bold">Комунальні платежі </span><span class="green--text">"Єдина квитанція"</span></h3>
                  <p class="mb-1 grey--text text--darken-1 exp-subtitle" :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : ''" style="font-size: 1.1em !important;">
                    Ви зможете дізнатися стан розрахунків по наданим Вам послугам та оплати ті, які вважаєте за потрібне
                  </p>
                </div>
              </v-row>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pl-0 pr-0 exp-content">
            <v-container class="container-max-width pa-0">
              <v-card class="mb-0 grey lighten-5 px-6 pt-2 py-4" tile flat>
                <v-row no-gutters  align="center">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="addressesSelectList"
                          label="Мої адреси"
                          item-text="address"
                          item-color="success"
                          hide-details
                          outlined
                          light
                          v-model="selectAddress"
                        />
                      </v-col>
                      <v-col cols="12" class="pt-3 pb-0 text-right pr-3">
                        <SearchDialog :text='true' :color="'grey darken-1'" :background='true' :outlined='false' :showIcon='true' :minWidth="'54px'" :showButtonName='true' @getSecretIdFromSearch="getSecretIdFromSearch"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat v-if="!addresses.length" class="px-3">
                <v-card-text class="pb-2 grey--text text--darken-2">
                    <v-row align="center" justify="space-between" style="height: 150px">
                      <v-col xs="12" sm="12" md="12">
                        <p class="subtitle-1 font-weight-light text-center" >Перелік адрес порожній. Додайте адресу(-и) за допомогою команди "Додати адресу"</p>
                      </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
              <div v-for="(address, index) in getAddresses" :key="address.secret_id" :class="index + 1 === (addresses.length || 0) ? '' : 'mb-3' ">
                <v-card flat class="px-3">
                  <!-- Картка команд по створені нових послуг/шаблонів-->
                  <v-card-text class="pb-2 pr-3">
                    <v-row no-gutters  align="center" justify="space-between">
                      <v-col cols="12" xs="12" sm="auto" md="auto" class="py-0">
                        <h3 class="font-weight-regular subtitle-1 text-left">{{ address.title }}</h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="auto"
                        sm="auto"
                        xs="12"
                        class="py-0 text-right"
                      >
                        <div class="d-inline-flex">
                          <v-btn class="body-2 mr-2" color="primary lighten-1" outlined style="display: none">Додати шаблон</v-btn>
                          <!--                      <v-btn class="btn-image-only" outlined>-->
                          <!--                        <v-icon color="success" dark>mdi-delete-variant</v-icon>-->
                          <!--                      </v-btn>-->
                          <confirmDialog @confirmModal="confirmRemoveAddress" :item="index" :color="'grey darken-1'" :background='true' :class="'font-weight-regular body-2 grey--text text--darken-2'" :small="false" :fab="false" :outlined="false" :text="true" icon="mdi-delete-variant" :right="true" :minWidth="'54px'" :divider="false" :headerColor="'error white--text mb-1'">
                            <template v-slot:header>
                              Вилучити адресу?
                            </template>
                            <template v-slot:content>Якщо Ви передумаєте, ви знову зможете додати цю адресу до свого списку</template>
                          </confirmDialog>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!--              <v-divider></v-divider>-->
                  <!-- Основна картка адреси для служю, розділена на два блоки-->
                  <v-card-text :class="`${$vuetify.breakpoint.smAndUp ? 'px-5' : 'px-2' }`">
                    <!-- (Блоки №1, №2)-->
                    <v-row no-gutters  align="start"
                           justify="space-around"
                           class="mt-1 mb-4 fill-height">
                      <!-- (Блок №1). Перелік служб-->
                      <v-col :cols="cols(0, 10, 10, 12)">
                        <v-card class="pb-0">
                          <v-col class="pa-0">
                            <!-- Заголовок таблиці-->
                            <v-row no-gutters
                                   align="center"
                                   class="py-2 pl-3 text-md-right text-sm-center grey--text caption font-weight-light">
                              <v-col cols="12" xs="12" sm="12" md="5"
                                     class="text-md-left text-sm-left pl-2 py-0"
                                     :class="`${$vuetify.breakpoint.smAndDown ? 'pl-3' : 'pl-5 pa-1' }`">
                                <div>Послуга</div>
                              </v-col>
                              <v-col cols="12" xs="12" sm="12" md="7" class="py-0">
                                <v-row no-gutters >
                                  <v-col cols="6" xs="6" sm="4" md="6"
                                         class="text-md-right text-sm-left"
                                         :class="`${$vuetify.breakpoint.smAndDown ? 'pl-3' : 'pa-1' }`">
                                    <div>Особовий рахунок</div>
                                  </v-col>
                                  <v-col cols="6" xs="6" sm="4" md="3"
                                         class="text-md-right text-sm-center text-right"
                                         :class="`
                                    ${$vuetify.breakpoint.xsOnly ? 'pr-3' : '' }
                                    ${$vuetify.breakpoint.smOnly ? 'pl-3' : '' }
                                    ${$vuetify.breakpoint.mdAndUp ? 'pa-1' : '' }
                                    `">
                                    <div>Місяць</div>
                                  </v-col>
                                  <v-col cols="12" xs="12" sm="4" md="3"
                                         class="text-md-right text-sm-right pr-2"
                                         :class="`${$vuetify.breakpoint.smAndDown ? 'pl-3 pr-3' : 'pr-4 pa-1' }`">
                                    <div>Сума</div>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-divider/>
                            <!-- Кінець заголовока таблиці-->
                            <!----<v-divider></v-divider>-->
                            <!-- Цикл по службах. Рядок таблиц іf-->
                            <v-hover
                              v-for="item in address.services.slice(0, 4)"
                              :key="`${item.service.id}-${item.person_id_internal}`"
                              :class="`${$vuetify.breakpoint.smAndDown ? 'border-bottom py-3' : ''} font-weight-light body-2 black--text add-cursor-pointer pl-3`">
                              <v-row no-gutters
                                     v-show="!item.hidden"
                                     align="center"  offset-lg-1
                                     slot-scope="{ hover }"
                                     :class="`${hover ? 'transition-fast-in-fast-out green lighten-5': '' } text-md-right text-sm-center py-2`"
                                     @click="pushToDetailView(address)">
                                <!-- Рядок назва служби та іконка-->
                                <v-col cols="12" xs="12" sm="12" md="5"
                                       class="text-md-left text-sm-left font-weight-light"
                                       :class="`${$vuetify.breakpoint.mdAndUp ? 'border-right pl-5' : 'pa-1 pl-3 pr-3'}`">
                                  <v-row no-gutters
                                         align="center"
                                         :class="`${$vuetify.breakpoint.mdAndUp ? 'pl-1' : ''}`">
                              <span>
                                <i :class="`${getIcon(item.service.config)} pr-3`"/>
                              </span>
                                    <div :style="$vuetify.breakpoint.xsOnly ? 'width: 80%; padding-bottom: 2px;' : ''">
                                      <span class="d-block font-weight-regular grey--text text--darken-2" :class="$vuetify.breakpoint.xsOnly ? 'mb-1' : ''">{{ item.service.organization.name }}</span>
                                      <span class="d-block font-weight-thin grey--text text--darken-3">{{ item.service.name }}</span>
                                    </div>
                                  </v-row>
                                </v-col>
                                <!-- Рядок особовий рахунок-->
                                <v-col cols="12" xs="12" sm="12" md="7">
                                  <v-row no-gutters align="center" class="py-0">
                                    <v-col cols="6" xs="6" sm="4" md="6"
                                           class="text-sm-left text-md-right"
                                           :class="`${$vuetify.breakpoint.smAndDown ? 'pl-10' : 'pa-2' }`"
                                    >
                                      <div :class="`${$vuetify.breakpoint.smAndDown ? 'pl-1' : '' }`">{{ item.person_id_internal_text || 'відсутній' }}</div>
                                    </v-col>
                                    <!-- Рядок місяць бази-->
                                    <v-col cols="6" xs="6" sm="4" md="3"
                                           class="text-right text-sm-center text-md-right"
                                           :class="`
                                      ${$vuetify.breakpoint.xsOnly ? 'pl-3 pr-3' : '' }
                                      ${$vuetify.breakpoint.smOnly ? 'pl-3' : '' }
                                      ${$vuetify.breakpoint.mdAndUp ? 'pa-1' : '' }
                                      `"
                                    >
                                      <div>{{ getMonth(item.date, item.service.month_difference) }}</div>
                                    </v-col>
                                    <!-- Рядок до оплати-->
                                    <v-col cols="12" xs="12" sm="4" md="3"
                                           class="text-md-right text-sm-right"
                                           :class="`
                                      ${addClassToNumber(item.due, 'font-weight-medium')}
                                      ${$vuetify.breakpoint.xsOnly ? 'pl-10 pt-1' : ''}
                                      ${$vuetify.breakpoint.smOnly ? 'pa-1 pr-3' : ''}
                                      ${$vuetify.breakpoint.mdAndUp ? 'pa-1 pr-4' : ''}`"
                                    >
                                      <div :class="`${$vuetify.breakpoint.xsOnly ? 'pl-1' : '' }`">{{ item.due | hrn }}</div>
                                    </v-col>
                                  </v-row>
                                </v-col>

                              </v-row>
                            </v-hover>
                            <v-hover
                              :class="`${$vuetify.breakpoint.smAndDown ? 'border-bottom py-3' : ''} font-weight-light body-2 black--text add-cursor-pointer pl-3`">>
                              <v-row
                                no-gutters
                                align="center"  offset-lg-1
                                justify="center"
                                slot-scope="{ hover }"
                                :class="`${hover ? 'transition-fast-in-fast-out green lighten-3': 'grey lighten-4' } text-center py-2`"
                                @click="pushToDetailView(address)"
                              >
                                <v-col
                                  class="text-uppercase display-1">
                            <span
                              style="height: 20px !important; line-height: 4px !important; display: block;"
                              :class="`${hover ? 'white--text': 'grey--text text--darken-1' }`"
                            >...</span>
                                </v-col>
                              </v-row>
                            </v-hover>



                            <v-row no-gutters   v-if="!address.services">
                              <v-col class="text-sm-center text-md-center pa-4">
                                Таблиця послуг порожня
                              </v-col>
                            </v-row>

                            <!-- Кінець цикла по службах. Кінець рядок таблиці-->
                          </v-col>
                        </v-card>
                      </v-col>
                      <v-col :cols="cols(0, 10, 10, 12)" v-if="!old_version">
                        <v-container fluid class="pa-1 pt-4">
                          <h3 class="mt-5 mb-2 text-uppercase">Додаткові платежі/шаблони</h3>
                          <v-divider class="pb-3" ></v-divider>

                          <v-row align="center" justify="space-between">
                              <template-item
                                v-for="(item, index) in templates" :key="index"
                                :icon="item.icon"
                                :name="item.name"
                                :organization="item.organization"
                                :serviceName="item.serviceName"
                                :payDate="item.payDate"
                                :sum="item.sum"
                              />
                              <v-card color="grey lighten-4" style="cursor: pointer;" class="py-6 ma-2 card-template"  :width="cardWidth()" height="183px" @click.stop="addNewTemplate">
                                <div style="position: relative; top: 75%; transform: translateY(-50%); height: 100%;">
                                  <div class="text-center pb-1">
                                    <v-avatar color="white" size="32px">
                                      <v-icon size="28px">mdi-plus</v-icon>
                                    </v-avatar>
                                  </div>
                                  <div class="text-center pt-0">
                                    <span class="subtitle-1 grey--text text--darken-2">Створити новий платіж/шаблон</span>
                                  </div>
                                </div>
                              </v-card>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <!-- (Блоки №1, №2)-->
                  </v-card-text>
                </v-card>
              </div>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="!old_version" class="grey lighten-5 mb-3" @change="onTemplateExpChange">
          <v-expansion-panel-header class="pl-3 subtitle-2">
            <div class="py-1">
              <v-row no-gutters class="flex-nowrap" align="start">
                <span>
                  <v-icon
                    size="56px"
                    color="info"
                    class="pr-4">
                    mdi-card-text-outline
                  </v-icon>
                </span>
                <div style="position: relative; top: 5px; font-size: 0.99em !important;">
                  <h3 class="text-uppercase grey--text text--darken-1">Збережені платежі або шаблони</h3>
                  <p class="mb-1 grey--text text--darken-1 exp-subtitle" :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : ''" style="font-size: 1.1em !important;">
                    Збережені платежі та шаблони, які не відносяться до адреси
                  </p>
                </div>
              </v-row>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid px-8>
              <v-row align="center" justify="space-between">
                <template-item
                  v-for="(item, index) in templates" :key="index"
                  :icon="'mdi-cash-plus'"
                  :name="item.name"
                  :organization="item.organization"
                  :serviceName="item.serviceName"
                  :payDate="item.payDate"
                  :sum="item.sum"
                  :color="'success'"
                  :width="400"
                  :large="false"
                />
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
  import {getIcon, addClassToNumber, assignServices, assignMeters, getSumPay, cols} from "@/utils";
  import SearchDialog from '@/components/searchAccount/SearchDialog'
  import AccountAPI from '@/api/account'
  import AddressAPI from '@/api/address'
  import ConfirmDialog from '@/components/global/confirmDialog'
  import CategoriesList from "@/components/templates/CategoriesList"
  import SubCategoriesList from "@/components/templates/SubCategoriesList"
  import TemplateItem from "@/components/templates/TemplateItem"

  const OLD_VERSION = false

  export default {
    name: 'addresses',
    components: {
      SearchDialog,
      ConfirmDialog,
      CategoriesList,
      SubCategoriesList,
      TemplateItem
    },
    data () {
      return {
        old_version: true,
        addresses: [],
        addressRelation: {},
        addressesSelectList: [],
        selectAddress: null,
        innerPath: '/templates',
        panel: this.$route.query["group"] ? 0 : OLD_VERSION ? 0 : 1,
        dialogFullScreen: false,
        currentComponent: 'categories-list',
        schema: '',
        group: '',
        openDialog: undefined,
        templates: [
          { name: 'Плата за моє ОСББ (Білогірська, 40)', organization: 'ОСББ "Білогірська, 40"', serviceName: "Внески за управління будинком", payDate: "10.03.2020", sum: "0.00" },
          { name: 'За харчування в садочку', organization: 'ДНЗ №13"', serviceName: "Плата за харчування", payDate: "10.04.2020", sum: "230.00" },
          { name: 'Колумбус', organization: 'Колумбус"', serviceName: "Плата за інтернет", payDate: "08.04.2020", sum: "90.00" }
        ]
      }
    },
    methods: {
      async getApiAddresses() {
        let {data} = await AccountAPI.dashboard();
        let addressRelation = {};
        let addressesSelectList = [
          { address: 'Показати усі адреси', value: null }
        ];

        data.addresses.forEach((adr, i) => {
          adr.services = assignServices(adr.services, true);
          adr.meters = assignMeters(adr.meters, adr.services);
          addressRelation[adr.secret_id] = {id: i, schema: adr.schema, full_name: adr.full_name, title: adr.title};
          addressesSelectList.push(
            { address: adr.title, value: adr.secret_id}
          );
          adr.sum_pay = getSumPay(adr);
        });
        this.addresses = data.addresses;
        this.addressRelation = addressRelation;
        this.addressesSelectList = addressesSelectList;
      },
      async addNewAddress(value) {
        try {
          await AddressAPI.addressAdd(value);
          return true
        } catch (e) {
          return false;
        }
      },
      addressesFiltered(address_id) {
        return this.addresses.filter(item => item.secret_id === address_id);
      },
      getMonth(date, difference = 0) {
        return this.$moment(date, 'YYYY-MM-DD').add(difference, 'month').format('MMMM');
      },
      dueSum(address_id) {
        const address = this.addresses.find(item => item.address_id === address_id);
        return address.services.reduce((total, s) => total + s.due, 0);
      },
      payCurrentMonthSum(address_id) {
        const address = this.addresses.find(item => item.address_id === address_id);
        return address.services.reduce((total, s) => total + s.paid_lastmonth, 0);
      },
      pushToDetailView (address) {
        this.$router.push({ name: 'authenticated-account-view', params: { item: address, schema: address.schema, secret_id: address.secret_id }})
      },
      getSecretIdFromSearch (value) {
        if (value) {
          let addresses = [];
          addresses.push(value);
          this.addNewAddress(addresses).then((success) => {
            if (success) {
              this.getApiAddresses();
              this.$snackbar("Адреса додана успішно", 'success')
            } else {
              this.$snackbar("Адреса не додана", 'error')
            }
          }).catch(() => {
            this.$snackbar("Помилка додавання адреси", 'error')
          })
        }
      },
      async removeAddress(item) {
        try {
          const currentRow = this.addresses[item];
          await AddressAPI.addressRemove(currentRow.secret_id);
          this.addresses.splice(item, 1);
          this.$snackbar("Адреса вилучена успішно", 'success')
        } catch (e) {
          this.$snackbar("Помилка вилучення адреси", 'error')
        }
      },
      confirmRemoveAddress(value) {
        if (value.question) {
          this.removeAddress(value.item);
        }
      },
      onTemplateExpChange() {
        if (this.panel === 0) {
          this.$nextTick(() => {
              this.panel = 1
            }
          )
        }
      },
      getComponent() {
        const componentList = {
          "categories-list": CategoriesList,
          "sub-categories-list": SubCategoriesList
        }

        return componentList[this.currentComponent || 'categories-list'];
      },
      onComponentChange(e) {
        this.schema = e.schema;
        this.group = e.group;
        this.currentComponent = e.component;
        this.openDialog = e.openDialog;

        switch (true) {
          case e.openDialog === true: {
            this.dialogFullScreen = true;
            break;
          }
          case e.openDialog === false: {
            this.dialogFullScreen = false;
            break;
          }
        }
      },
      addNewTemplate() {
        this.currentComponent = 'categories-list';
        this.dialogFullScreen = true;
        this.openDialog = undefined;
      },
      cardWidth() {
        const breakpoint = this.$vuetify.breakpoint.name;
        switch (true) {
          case breakpoint === 'xs': {
            return '100%'
          }
          case breakpoint === 'sm': {
            return '47%'
          }
          case breakpoint === 'md': {
            return '350px'
          }
          case breakpoint === 'lg': {
            return '350px'
          }
          case breakpoint === 'xl': {
            return '380px'
          }
        }
      },
      getIcon,
      addClassToNumber,
      cols,
    },
    computed: {
      getAddresses () {
        return this.selectAddress ? this.addressesFiltered(this.selectAddress) : this.addresses
      }
    },
    mounted () {
      this.panel = this.old_version ? 0 : 1
      this.getApiAddresses();
      this.$eventBus.$on("modalRoute", this.onComponentChange)
    }
  }
</script>

<style scoped lang="scss">
  .services-icons-font{
    font-size: 20px !important;
    position: relative;
    top: 1px;
  }
  .container-max-width {
    max-width: 1560px;
  }
  .btn-image-only {
    min-width: 54px;
  }
  .border-right {
    /*border-right: 1px solid #eeeeee;*/
  }
  .border-bottom {
    border-bottom: 1px solid #eeeeee;
  }
  .border-left {
    border-left: 1px solid #eeeeee;
  }
  .display-end {
    display: inline-flex;
  }
  .add-cursor-pointer {
    cursor: pointer;
  }
  .address {
    &::v-deep .v-btn__content .v-icon {
      color: inherit !important;
    }
  }
  .address-select {
    &::v-deep .v-select__selection.v-select__selection--comma {
      color: grey !important;
    }
  }
  .exp-content {
    &::v-deep .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
  .exp-subtitle {
    font-size: 0.91em !important;
  }
  @media screen and (max-width: 800px) and (min-width: 600px) {
    .card-template {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 1375px) and (min-width: 945px) {
    .card-template {
      width: 47% !important;
    }
  }
</style>
