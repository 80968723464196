<template>
  <div>
    <v-textarea
      :name="name"
      :label="schema.label"
      :placeholder="schema.placeholder"
      :disabled="schema.disabled"
      :rules="getValidators()"
      :value="value"
      @change="emitEvent"
      class="my-0"
      v-bind="$attrs.styleSchema || {}"
      v-if="schema.fieldType === 'textarea'"
    >
    </v-textarea>
    <v-text-field
      :name="name"
      :type="fieldType_"
      :label="schema.label"
      :placeholder="schema.placeholder"
      :hint="schema.hint"
      :disabled="schema.disabled"
      :rules="getValidators()"
      :value="value"
      @change="emitEvent"
      @keydown="keyDownEvent($event, $event.target.value)"
      class="my-0"
      v-bind="$attrs.styleSchema || {}"
      v-else
    >
    </v-text-field>
  </div>

</template>

<script>
    import { VALIDATION_RULES } from "@/utils";

    export default {
        name: "DynamicTextField",
        inheritAttrs: false,
        props: {
            value: {
                default() {
                    return undefined
                }
            },
            name: {
              type: String,
              default() {
                return 'text';
              }
            },
            fieldType: {
                type: String,
                default() {
                    return 'text';
                }
            },
            schema: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        computed: {
         fieldType_() {
           let special = {
             'currency': 'text',
           };

           if (special[this.schema.fieldType]) {
             return 'text'
           } else {
             return this.schema.fieldType;
           }

         }
        },
        methods: {
          getValidators() {
            if (this.schema.validators) {
              let validators = [];
              this.schema.validators.forEach(elem => {
                validators.push(VALIDATION_RULES[elem])
              });
              return validators
            }
          },
          currencyInput(e) {
            this.$nextTick(() => {
                let a = e.target.value === null ? '0' : e.target.value.toString();
                a = a.replace(',', '.').replace(' ', '');
                this.$emit('input', a);
              }
            );
          },
          emitEvent(e) {
            this.$emit('input', e);
            this.$parent.$emit('getAppointment')
          },
          decimalFormat(e, value) {
            if ((e.keyCode === 9) || (e.keyCode === 13) || (e.keyCode === 8)) {
              return true;
            }

            if (e.shiftKey) e.preventDefault();

            let charCode = (e.which) ? e.which : event.keyCode;
            let localValue = '' + value;
            let keyNumbers = [48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105];
            let keySeparators = [110, 188, 190];
            let KeyNumber = false;
            let keySeparartor = false;
            // let currentChar = e.key;

            if (keyNumbers.includes(charCode)) {
              let newlocalValue = localValue;
              const separatorPosition = newlocalValue.indexOf('.');
              if (separatorPosition !== -1) {
                if (newlocalValue.substr(separatorPosition).length <= 2) {
                  return true;
                } else {
                  e.preventDefault();
                  return false;
                }
              } else {
                return true;
              }
            }
            if (keySeparators.includes(charCode)) {
              if (e.key.toLowerCase() === 'ю' || e.key.toLowerCase() === 'б') {
                e.preventDefault();
              }
              keySeparartor = true;
              if (localValue.indexOf('.') === -1) {
                localValue += '.';
              } else {
                e.preventDefault();
              }
            }


            if ((!KeyNumber) && (!keySeparartor)) {
              e.preventDefault();
              return false
            }
          },
          keyDownEvent(e, value) {
            if (this.schema.fieldType === 'currency') {
              this.decimalFormat(e, value)
            } else {
              return true;
            }
          }
        },
    }
</script>

<style scoped>

</style>
